import "@splidejs/splide/css/core";
import Splide from "@splidejs/splide";

export default (ops, target) => ({
  instance: null,

  init() {
    const el = target ? this.$el.querySelector(target) : this.$el;

    this.instance = new Splide(el, {
      ...ops,
    }).mount();
  },
});
