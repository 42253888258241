/*###########################################################################
// Scroll to
//#########################################################################*/

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        let offsetAmount = document.querySelector('header').clientHeight;
        const target = document.querySelector(this.getAttribute('href'));
        const targetPosition = window.pageYOffset + target.getBoundingClientRect().top;

        // If page nav menu exists, factor it in.
        //
        if (document.querySelector('.stick[data-block-type="navMenu"]') !== null) {
            const pageNav = document.querySelector('.stick[data-block-type="navMenu"]').firstElementChild.firstElementChild
            if (pageNav !== null) {
                offsetAmount += pageNav.clientHeight
            }
        }

        window.scrollTo({
            top: targetPosition - offsetAmount,
            behavior: 'smooth'
        });
    });
});