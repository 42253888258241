// @ts-check

import { debounce, split } from "lodash";

/**
 * Returns an object containing properties related to product filtering and pagination.
 * @returns {object} The products object.
 */
export default function products(entries = "", categories = "") {
  return {
    /** @type {object[]} An array of category objects. */
    categories: [],

    /** @type {object[]} An array of product objects. */
    entries: [],

    /** @type {(string|number)[]} An array of product range values. */
    productRanges: [], // Selected product ranges

    /** @type {(string|number)[]} An array of age range values. */
    ageRanges: [], // Selected age ranges

    /** @type {(string|number)[]} An array of product type values. */
    productTypes: [], // Selected product types

    /** @type {(string|number)[]} An array of dietary requirement values. */
    dietaryRequirements: [], // Selected dietary requirements

    /** @type {(string|number)[]} An array of collection values. */
    collections: [], // Selected collections

    /** @type {boolean} Indicates whether the product range filter is visible. */
    showProductRangeFilter: false,

    /** @type {boolean} Indicates whether the age filter is visible. */
    showAgeFilter: false,

    /** @type {boolean} Indicates whether the product filter is visible. */
    showProductTypeFilter: false,

    /** @type {boolean} Indicates whether the dietary filter is visible. */
    showDietaryFilter: false,

    /** @type {boolean} Indicates whether the collections filter is visible. */
    showCollectionsFilter: false,

    /** @type {number} The current page number. */
    page: 1, // Current page

    /** @type {number} The number of entries to display per page. */
    perPage: 12, // Number of entries per page

    /** @type {string} The current sort order. */
    sortBy: "azAsc",

    /** @type {boolean} Indicates whether data is currently being loaded. */
    loading: false, // true when loading data

    /** @type {HTMLInputElement[]} An array of filter input elements. */
    filterEls: [],

    /**
     * Init
     */
    init() {
      this.categories = JSON.parse(categories);
      this.entries = JSON.parse(entries);

      // Get the filter elements
      this.filterEls = this.$el.querySelectorAll("[data-filter]");

      // Set the selected filters from the URL
      const url = new URL(window.location.toString());

      this.productRanges = url.searchParams.get("range")
        ? split(url.searchParams.get("range"), ",").map(Number)
        : [];
      this.ageRanges = url.searchParams.get("age")
        ? split(url.searchParams.get("age"), ",").map(Number)
        : [];
      this.productTypes = url.searchParams.get("type")
        ? split(url.searchParams.get("type"), ",").map(Number)
        : [];
      this.dietaryRequirements = url.searchParams.get("dietary")
        ? split(url.searchParams.get("dietary"), ",").map(Number)
        : [];
      this.collections = url.searchParams.get("collection")
        ? split(url.searchParams.get("collection"), ",").map(Number)
        : [];

      const pageParam = url.searchParams.get("page");
      this.page = pageParam ? +pageParam : 1;

      // Do not allow the user to select filters while loading
      this.$watch("loading", (loading) => {
        if (loading) {
          this.filterEls.forEach((el) => {
            el.disabled = true;
          });
        } else {
          this.filterEls.forEach((el) => {
            // Keep track of the disabled state using a data attrib
            // So that we don't re-enable filters that were disabled by null results
            if (!el.dataset.disabled) {
              el.disabled = false;
            }
          });
        }
      });

      this.$watch("productRanges", () => {
        this.loading = true;
        debounce(() => {
          this.handleFilterChange();
        }, 320)();
      });

      this.$watch("ageRanges", () => {
        this.loading = true;
        debounce(() => {
          this.handleFilterChange();
        }, 320)();
      });

      this.$watch("productTypes", () => {
        this.loading = true;
        debounce(() => {
          this.handleFilterChange();
        }, 320)();
      });

      this.$watch("dietaryRequirements", () => {
        this.loading = true;
        debounce(() => {
          this.handleFilterChange();
        }, 320)();
      });

      this.$watch("collections", () => {
        this.loading = true;
        debounce(() => {
          this.handleFilterChange();
        }, 320)();
      });

      this.$watch("sortBy", () => {
        this.handleSortChange();
      });

      // watch for changes to page and replace the history state
      this.$watch("page", () => {
        this.replaceHistoryState();
      });

      // Set the initial product counts
      this.updateProductCounts();
    },

    filteredEntries(omitCategory = "") {
      return this.entries
        .filter((entry) => {
          if (omitCategory === "productRanges") {
            return true;
          } else {
            if (this.productRanges.length === 0) {
              return true;
            } else {
              return this.productRanges.some((selectedProductRange) => {
                return entry.productRanges[selectedProductRange];
              });
            }
          }
        })
        .filter((entry) => {
          if (omitCategory === "productAgeRanges") {
            return true;
          } else {
            if (this.ageRanges.length === 0) {
              return true;
            } else {
              return this.ageRanges.some((selectedAgeRange) => {
                return entry.productAgeRanges[selectedAgeRange];
              });
            }
          }
        })
        .filter((entry) => {
          if (omitCategory === "productTypes") {
            return true;
          } else {
            if (this.productTypes.length === 0) {
              return true;
            } else {
              return this.productTypes.some((selectedProductType) => {
                return entry.productTypes[selectedProductType];
              });
            }
          }
        })
        .filter((entry) => {
          if (omitCategory === "productDietaryRequirements") {
            return true;
          } else {
            if (this.dietaryRequirements.length === 0) {
              return true;
            } else {
              return this.dietaryRequirements.every(
                (selectedDietaryRequirement) => {
                  return entry.productDietaryRequirements[
                    selectedDietaryRequirement
                  ];
                }
              );
            }
          }
        })
        .filter((entry) => {
          if (omitCategory === "productCollections") {
            return true;
          } else {
            if (this.collections.length === 0) {
              return true;
            } else {
              return this.collections.some((selectedCollection) => {
                return entry.productCollections[selectedCollection];
              });
            }
          }
        });
    },

    /**
     * handleFilterChange
     */
    handleFilterChange() {
      this.page = 1;
      this.replaceHistoryState();
      this.loading = false;
      this.updateProductCounts();
    },

    /**
     * updateProductCounts
     */
    updateProductCounts() {
      this.filterEls.forEach((filter) => {
        const categoryGroup = filter.dataset["filterCategorygroup"];
        const category = filter.dataset["filterCategory"];

        if (categoryGroup && category) {
          const countEl = filter.querySelector(".filter__count");

          if (!this.isOptionFound(categoryGroup, +category, categoryGroup)) {
            // This tells us that the option does not exist in the filtered entries - it will get dimmed
            countEl.innerHTML = "(0)";
          } else {
            let count = this.entryCountByCategory(
              categoryGroup,
              +category,
              true
            );

            if (count === 0) {
              // We know this option exists, so we just have to count how many times it appears in the entries
              count = this.optionCount(categoryGroup, +category, categoryGroup);
            }

            countEl.innerHTML = `(${count})`;
          }
        }
      });
    },

    /**
     * handleSortChange
     */
    handleSortChange() {
      if (this.sortBy === "azAsc") {
        this.entries.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      }
      if (this.sortBy === "azDesc") {
        this.entries.sort((a, b) => {
          return b.title.localeCompare(a.title);
        });
      }
      this.page = 1;
      this.replaceHistoryState();
      this.loading = false;
    },

    /**
     * isOptionFound
     * @param {string} option
     * @param {number} targetId
     * @returns {boolean}
     */
    isOptionFound(option, targetId, omitCategory = "") {
      for (let entry of this.filteredEntries(omitCategory)) {
        if (Object.keys(entry[option]).includes(targetId.toString())) {
          return true;
        }
      }
      return false;
    },

    /**
     * optionCount
     * @param {string} option
     * @param {number} targetId
     * @returns {number}
     */
    optionCount(option, targetId, omitCategory = "") {
      let count = 0;
      for (let entry of this.filteredEntries(omitCategory)) {
        if (Object.keys(entry[option]).includes(targetId.toString())) {
          count++;
        }
      }
      return count;
    },

    /**
     * entryCountByCategory
     * @param {string} category
     * @param {number} id
     * @param {boolean} useFilteredEntries
     */
    entryCountByCategory(category, id, useFilteredEntries = false) {
      const entries = useFilteredEntries
        ? this.filteredEntries()
        : this.entries;
      return entries.reduce((count, entry) => {
        if (Object.keys(entry[category]).includes(id.toString())) {
          count++;
        }
        return count;
      }, 0);
    },

    getCategoryTitleByID(group, id) {
      const category = this.categories[group].find((c) => c.id === id);
      return category ? category.title : null;
    },

    // Function to show active filter titles, comma separated
    get activeFilterTitles() {
      const activeFilters = [];
      if (this.productRanges.length > 0) {
        this.productRanges.forEach((productRange) => {
          activeFilters.push(
            this.getCategoryTitleByID("productRange", productRange)
          );
        });
      }
      if (this.ageRanges.length > 0) {
        this.ageRanges.forEach((ageRange) => {
          activeFilters.push(
            this.getCategoryTitleByID("productAgeRange", ageRange)
          );
        });
      }
      if (this.productTypes.length > 0) {
        this.productTypes.forEach((productType) => {
          activeFilters.push(
            this.getCategoryTitleByID("productType", productType)
          );
        });
      }
      if (this.dietaryRequirements.length > 0) {
        this.dietaryRequirements.forEach((dietaryRequirement) => {
          activeFilters.push(
            this.getCategoryTitleByID(
              "productDietaryRequirements",
              dietaryRequirement
            )
          );
        });
      }
      if (this.collections.length > 0) {
        this.collections.forEach((collection) => {
          activeFilters.push(
            this.getCategoryTitleByID("productCollections", collection)
          );
        });
      }

      return activeFilters.join(", ");
    },

    /**
     * paginatedEntries
     */
    get paginatedEntries() {
      // Calculate the start and end entries for the current page
      const start = (this.page - 1) * this.perPage;
      const end = start + this.perPage;

      // Return the entries for the current page
      return this.filteredEntries().slice(start, end);
    },

    /**
     * pagination
     */
    get pagination() {
      // Calculate the number of pages
      const pages = this.totalPages;

      // Create an array of pages
      const pagination = Array.from({ length: pages }, (_, i) => i + 1);

      // If there are more than 2 pages, filter out the pages that are not within 2 of the current page
      if (pages > 2) {
        return pagination.filter(
          (page) =>
            page === 1 ||
            page === this.page ||
            page === pages ||
            (page >= this.page - 2 && page <= this.page + 2)
        );
      } else {
        // Otherwise, return all the pages
        return pagination;
      }
    },

    /**
     * total pages
     */
    get totalPages() {
      // Calculate the number of pages
      return Math.ceil(this.filteredEntries().length / this.perPage);
    },

    /**
     * @param {number} page
     */
    goToPage(page) {
      // Set the current page
      this.page = page;

      // Scroll to #results
      const results = document.querySelector("#results");
      if (results) {
        results.scrollIntoView({
          behavior: "smooth",
        });
      }
    },

    /**
     * replaceHistoryState
     */
    replaceHistoryState() {
      const params = new URLSearchParams(window.location.search);
      params.set("age", this.ageRanges.join(","));
      params.set("type", this.productTypes.join(","));
      params.set("dietary", this.dietaryRequirements.join(","));
      params.set("collection", this.collections.join(","));
      params.set("page", this.page);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params}`
      );
    },

    /**
     * Resets all filter values to empty arrays.
     */
    resetFilters() {
      this.ageRanges = [];
      this.productRanges = [];
      this.productTypes = [];
      this.dietaryRequirements = [];
      this.collections = [];
    },
  };
}
