export default function showMore(name, options) {
  return {
    name: name,
    options: options || {},
    open: false,

    init() {
      this.setupShowMore();
      window.addEventListener('resize', () => this.setupShowMore());
    },

    setupShowMore() {
      console.log('here');
      const textElement = document.querySelector(`.show-more-text`);
      const buttonElement = document.querySelector(`.show-more-button`);

      if (textElement && buttonElement) {
        const lineHeight = parseInt(window.getComputedStyle(textElement).lineHeight);
        const contentHeight = textElement.scrollHeight;

        if (contentHeight <= lineHeight * 3) {
          buttonElement.style.display = 'none';
        } else {
          buttonElement.style.display = 'block';
        }
      }
    },
  };
}