let _oldScroll = 0;

export default function header() {
  return {
    ddContentEls: [],
    disableScrollReveal: false,
    isStuck: false,
    isTransitioning: false,
    mobileBreakpoint: 1024,
    mobileMenuHeight: 0,

    init() {
      // Get all dropdown content elements
      this.ddContentEls = this.$el.querySelectorAll(
        ".site-header__dd__content"
      );

      /**
       * Handle changes when currentMenu state changes
       */
      this.$watch("$store.global.currentMenu", (state) => {
        // Dispatch event
        this.$dispatch("effect-menu", { state: !!state });

        // Lock desktop scroll when currentMenu is set
        this.$store.global.desktopMenuOpen =
          state && this.$store.global.isDesktop ? true : false;

        // Setting menu item and above mobile breakpoint
        if (state && this.$store.global.innerWidth) {
          // Set other dropdowns invisible
          this.setDropdownsInvisible(true, [this.$store.global.currentMenu]);
          // Set visibility for keyboard navigation
          this.$refs[this.$store.global.currentMenu].style.visibility =
            "visible";
        }
        // Unsetting menu item
        else {
          // Above mobile breakpoint
          if (this.$store.global.innerWidth) {
            this.setDropdownsInvisible(true);
          }
        }

        // Make all dropdown heights equal to the active menu height
        // Prevents inactive dropdowns from affecting scroll height
        if (state && this.$store.global.isMobile) {
          setTimeout(() => {
            let activeMenu = this.$refs[this.$store.global.currentMenu];
            let activeMenuHeight = activeMenu.getBoundingClientRect().height;
            this.ddContentEls.forEach((menu) => {
              menu.style.height = `${activeMenuHeight}px`;
              menu.style.overflow = "hidden";
            });
            activeMenu.style.height = "auto";
            activeMenu.style.overflow = "visible";
          }, 50);
        } else {
          this.ddContentEls.forEach((menu) => {
            menu.style.height = "auto";
            menu.style.overflow = "visible";
          });
        }
      });

      /**
       * Handle changes when searchOverlayOpen state changes
       */
      this.$watch("$store.global.searchOverlayOpen", (state) => {
        console.log("searchOverlayOpen", state);
        // Focus first input
        if (state) {
          setTimeout(() => {
            this.$el.querySelector("#q").focus();
          }, 10);
        }
      });

      // disableScrollReveal can be set outside of this component
      // to prevent jarring scroll reveal. This just resets it.
      this.$watch("disableScrollReveal", (state) => {
        if (state) {
          setTimeout(() => {
            this.disableScrollReveal = false;
          }, 2000);
        }
      });

      // Close menu when escape is pressed
      document.addEventListener("keyup", (e) => {
        if (e.key === "Escape" && this.$store.global.currentMenu) {
          let currentMenuButton = this.$el.querySelector(
            `nav button[aria-controls='${this.$store.global.currentMenu}'`
          );
          this.$store.global.currentMenu = "";
          currentMenuButton.focus();
        }
      });

      // Close menu when tabbing beyond the last menu item
      const lastFocusable = this.$el.querySelector("[data-last-focusable]");
      window.addEventListener("keydown", (e) => {
        if (
          e.key === "Tab" &&
          !e.shiftKey &&
          document.activeElement === lastFocusable
        ) {
          this.closeMenu();
        }
      });

      this.sticky();
    },

    handleDropdownClick(menuHandle) {
      if (this.isTransitioning) return;
      this.$store.global.currentMenu = this.$store.global.currentMenu === menuHandle ? "" : menuHandle;
    },

    toggleMobileMenu() {
      this.$store.global.currentMenu = "";
      this.$store.global.mobileMenuOpen = !this.$store.global.mobileMenuOpen;


    },

    sticky() {
      const observer = new IntersectionObserver(
        (event) => {
          this.isStuck = !event[0].isIntersecting;
        },
        {
          rootMargin: "-1px 0px 0px 0px",
          threshold: [1],
        }
      );

      observer.observe(this.$el);

      window.onscroll = (e) => {
        if (
          _oldScroll > window.scrollY &&
          this.isStuck &&
          !this.disableScrollReveal
        ) {
          this.$el.classList.remove("-out");
        } else {
          if (!this.$store.global.currentMenu && window.scrollY > 0) {
            this.$el.classList.add("-out");
          }
        }
        _oldScroll = window.scrollY;
      };
    },

    closeMenu() {
      if (this.isTransitioning) return;
      this.$store.global.currentMenu = "";
    },

    /**
     * Set all dropdowns to invisible
     * @param {boolean} withDelay - Whether to wait before setting invisible
     * @param {array} exclude - Array of dropdown IDs to exclude
     */
    setDropdownsInvisible(withDelay = false, exclude = []) {
      if (withDelay) this.isTransitioning = true;

      setTimeout(
        () => {
          this.ddContentEls.forEach((menu) => {
            if (!exclude.includes(menu.id)) {
              menu.style.visibility = "hidden";
            }
          });

          if (withDelay) this.isTransitioning = false;
        },
        withDelay ? 500 : 0
      );
    },
  };
}
