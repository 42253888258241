/* CSS */
import "@js/parts/css";

/* Non-alpine JS */
import { throttle } from "lodash";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";
import header from "@js/parts/header";
import oneModal from "@js/parts/oneModal";
import products from "@js/parts/products";
import showMore from "@js/parts/showMore";
import splideSlider from "@js/parts/splideSlider";

// Non Alpine
import "./parts/scrollBarChecker";
import "./parts/scrollTo";
// import "./parts/cookieMessage";

export default (ops, target) => ({
  instance: null,

  init() {
    const el = target ? this.$el.querySelector(target) : this.$el;

    this.instance = new Splide(el, {
      ...ops,
    }).mount();
  },
});

Alpine.plugin(collapse);
Alpine.plugin(intersect);

/* Register components */
Alpine.data("header", header);
Alpine.data("oneModal", oneModal);
Alpine.data("products", products);
Alpine.data("showMore", showMore);
Alpine.data("splideSlider", splideSlider);

Alpine.store("global", {
  currentMenu: "",
  desktopMenuOpen: false,
  searchOverlayOpen: false,
  innerWidth: window.innerWidth,
  get isDesktop() {
    return this.innerWidth >= this.mobileBreakpoint;
  },
  get isMobile() {
    return this.innerWidth < this.mobileBreakpoint;
  },
  mobileBreakpoint: 1024,
  mobileCategoryFiltersOpen: false,
  mobileMenuOpen: false,

  init() {
    // Listen to window resize, throttle calls and dispatch a custom event
    // Use @resize:window.window="yourResizeHandler" in components
    const handleResize = () => {
      this.innerWidth = window.innerWidth;
      window.dispatchEvent(new CustomEvent("resize:window"));
    };
    window.addEventListener("resize", throttle(handleResize, 100));
  },
});

Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
