const scrollBarChecks = {
    config: {
        scrollbar: '.scrollbar-check'
    },

    elements: {
        scrollbars: null
    },

    init() {
        const self = this;

        self.elements.scrollbars = document.querySelectorAll(self.config.scrollbar);
        self.elements.scrollbars.forEach(scrollbar => {
            
            self.scrollToActiveLink(scrollbar);
        });
    },

    scrollToActiveLink(scrollbar) {
        const links = scrollbar.querySelectorAll('a');
        const currentUrl = window.location.href;

        links.forEach(link => {
            if (link.href === currentUrl) {
                // Optionally, you can adjust the offset if needed
                const offset = link.offsetLeft;
                scrollbar.scroll({
                    left: offset,
                    behavior: 'smooth'
                });
            }
        });
    }
}

window.addEventListener('load', function () {
    scrollBarChecks.init();
});