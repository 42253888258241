export default function modal(name, options) {
  return {
    name: null,
    openState: false,
    openName: null,
    videoSrc: null,
    videoIframe: null,
    content: null,

    options: {
      overlayColor: "",
      closePosition: "top right",
      closeTransform: ["50%", "-50%"], // CSS: transformX, transformY
      contentMax: "640px", // Max width for content box
      gutters: "20px", // Left/right gutters for content box
      isVideo: false,
      history: false, // Use window history
    },

    get closePositionStyle() {
      let position = this.options.closePosition.split(" ");
      return `${position[0]}: 0; ${position[1]}: 0`;
    },

    init() {
      if (!name) {
        console.log("Name must be specified");
        return;
      }

      this.name = name;

      // Merge options

      if (options) {
        this.options = {
          ...this.options,
          ...options,
        };
      }

      if (this.options.isVideo) this.handleVideo();
    },

    setOpenState(value) {
      this.openState = value;
    },

    setOpenName(value) {
      this.openName = value;
    },

    handleOpen({ modalName, modalContent }) {
      if (this.name === modalName) {
        this.content = modalContent;
        this.setOpenState(true);
        this.setOpenName(modalName);

        if (this.options.isVideo && this.videoEl) {
          if (this.videoEl.tagName.toLowerCase() === "video") {
            this.videoEl.play();
          } else {
            // Is autoplay specified?
            const autoplayRegEx = new RegExp(/\&autoplay=(\d)/, "gm");
            const autoplay = [...this.videoEl.src.matchAll(autoplayRegEx)];

            if (autoplay && autoplay.length && autoplay[0][1] === "0") {
              this.videoEl.src = this.videoEl.src.replace(
                "&autoplay=0",
                "&autoplay=1"
              );
            } else {
              this.videoEl.src =
                this.videoEl.src +
                (this.videoEl.src.indexOf("?") < 0 ? "?" : "&") +
                "autoplay=1";
            }
          }
        }

        // Focus on modal
        document.querySelector(".modal").focus();

        // Lock scroll
        document.documentElement.style.overflow = "hidden";
        document.addEventListener("keydown", this.escapeClose.bind(this));
      }
    },

    escapeClose(e) {
      if (e.keyCode == 27) {
        this.handleClose();
      }
    },

    handleClose($dispatch, name) {
      $dispatch && $dispatch("modal-closed", this.name);
      this.setOpenState(false);

      this.content = null;

      // Replace state
      if (this.options.history) {
        window.history.replaceState(null, null, window.location.pathname);
      }

      // Effectively stops video
      if (this.options.isVideo && this.videoEl) {
        if (this.videoEl.tagName.toLowerCase() === "video") {
          this.videoEl.pause();
        } else {
          this.videoEl.src = this.videoEl.src
            .replace("&autoplay=1", "")
            .replace("?autoplay=1", "");
        }
      }

      // Unlock scroll
      document.documentElement.style.overflow = "initial";
      document.removeEventListener("keydown", this.escapeClose);
    },

    handleVideo() {
      // Store ref to video and original src
      let video = this.$el.querySelector(
        'iframe[src*="www.youtube.com"], iframe[src*="player.vimeo.com"], video'
      );
      if (video) {
        this.videoEl = video;
      }
    },
  };
}
